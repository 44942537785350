















































































































































































































































































































































































































































































































































































































































































































import { Component, Vue, Prop, Watch, Mixins } from "vue-property-decorator";
import { Notify } from "quasar";
import FormValidator from "@/components/mixins/FormValidator";

interface DataTraslado {
  rut: string;
  patente: string;
  nombre: string;
  apellido_paterno: string;
  apellido_materno: string;
  id_comuna: string;
  calle: string;
  numero: string;
  aclaratoria: string;
  telefono: string;
  email: string;
}
interface SelectOption {
  value: string | any;
  label: string;
}

@Component
export default class Traslado extends Mixins(FormValidator) {
  private win = window;
  private base = window.location;
  private rutContacto = "";
  private placaPatente = "";
  protected correoConfirmacion = "";
  protected dataTraslado: DataTraslado = {
    rut: "",
    patente: "",
    nombre: "",
    apellido_paterno: "",
    apellido_materno: "",
    id_comuna: "",
    calle: "",
    numero: "",
    aclaratoria: "",
    telefono: "",
    email: "",
  };
  private comuna = "Juan Fernández";
  private permiso: File | null = null;
  private revision: File | null = null;
  private factura: File | null = null;
  private certificado: File | null = null;
  private soap: File | null = null;
  private invalidFile = false;
  private cityList: any[] = [];
  private localeCalendar = {
    days: [
      "Domingo",
      "Lunes",
      "Martes",
      "Miércoles",
      "Jueves",
      "Viernes",
      "Sábado",
    ],
    daysShort: ["Dom", "Lun", "Mar", "Mie", "Jue", "Vie", "Sab"],
    months: [
      "Enero",
      "Febrero",
      "Marzo",
      "Abril",
      "Mayo",
      "Junio",
      "Julio",
      "Agosto",
      "Septiembre",
      "Octubre",
      "Noviembre",
      "Diciembre",
    ],
    monthsShort: [
      "Ene",
      "Feb",
      "Mar",
      "Abr",
      "May",
      "Jun",
      "Jul",
      "Ago",
      "Sep",
      "Oct",
      "Nov",
      "Dic",
    ],
  };
  private comunaOptions: SelectOption[] = [];
  private dataComuna: SelectOption = {
    value: null,
    label: "",
  };

  @Watch("rutContacto")
  private formatRutVecino(newValue: string) {
    newValue = newValue.replace(/\./g, "").toUpperCase();
    this.dataTraslado.rut = newValue;
    newValue = newValue.replace(/-/g, "");
    if (newValue.length > 5) {
      const rut = newValue
        .substring(0, newValue.length - 1)
        .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1.");
      const dv = newValue.substring(newValue.length - 1);
      this.rutContacto = `${rut}-${dv}`;
    } else {
      this.rutContacto = newValue
        .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1.")
        .toUpperCase();
    }
  }
  @Watch("placaPatente")
  private formatPlacaPatente(newValue: string) {
    // convierte amayúsculas
    newValue = newValue.replace(/[^a-zA-Z0-9]/g, "").toUpperCase();
    this.placaPatente = newValue;
    if (newValue.length == 6) {
      // const placa = newValue.substring(0, 6); // Los primeros 6 caracteres
      // const digito = newValue.charAt(6); // El último dígito
      // this.placaPatente = `${placa}-${digito}`;
      this.dataTraslado.patente = this.placaPatente;
    }
  }

  @Watch("dataComuna")
  private comunaWatcher(newValue: any) {
    this.dataTraslado.id_comuna = newValue.value;
  }

  private getComuna() {
    this.$axios("comunas").then((res) => {
      res.data.forEach((city: any) => {
        this.cityList.push({
          value: city.id,
          label: city.nombre,
        });
      });
    });
  }

  private mounted() {
    this.getComuna();
  }

  private onRejected(rejectedEntries) {
    // console.log(rejectedEntries[0].failedPropValidation);
    if (rejectedEntries[0].failedPropValidation == "max-file-size") {
      this.$swal({
        icon: "warning",
        title: "Tamaño documento",
        text: "El documento excede los 5MB permitidos",
      });
    }
  }

  private enviarForm() {
    if (
      this.dataTraslado.rut == "" ||
      this.validateRut(this.dataTraslado.rut) == false
    ) {
      Notify.create({
        color: "warning",
        message: "Por favor, ingrese un RUT válido.",
        icon: "report_problem",
        position: "top",
        timeout: 2500,
      });
      return;
    }
    if (this.dataTraslado.patente == "") {
      Notify.create({
        color: "warning",
        message: "Por favor, ingrese su Placa Patente.",
        icon: "report_problem",
        position: "top",
        timeout: 2500,
      });
      return;
    }
    if (this.dataTraslado.nombre == "") {
      Notify.create({
        color: "warning",
        message: "Por favor, ingrese su nombre.",
        icon: "report_problem",
        position: "top",
        timeout: 2500,
      });
      return;
    }
    if (this.dataTraslado.apellido_paterno == "") {
      Notify.create({
        color: "warning",
        message: "Por favor, ingrese su apellido paterno.",
        icon: "report_problem",
        position: "top",
        timeout: 2500,
      });
      return;
    }
    if (this.dataTraslado.apellido_materno == "") {
      Notify.create({
        color: "warning",
        message: "Por favor, ingrese su apellido materno.",
        icon: "report_problem",
        position: "top",
        timeout: 2500,
      });
      return;
    }
    if (this.dataTraslado.telefono == "") {
      Notify.create({
        color: "warning",
        message: "Por favor, ingrese su número de teléfono.",
        icon: "report_problem",
        position: "top",
        timeout: 2500,
      });
      return;
    } else if (this.dataTraslado.telefono.length < 9) {
      Notify.create({
        color: "warning",
        message: "Por favor, ingrese un teléfono válido (9 dígitos).",
        icon: "report_problem",
        position: "top",
        timeout: 2500,
      });
      return;
    }
    if (this.dataTraslado.email == "") {
      Notify.create({
        color: "warning",
        message: "Por favor, ingrese su correo.",
        icon: "report_problem",
        position: "top",
        timeout: 2500,
      });
      return;
    } else if (!this.validEmail(this.dataTraslado.email)) {
      Notify.create({
        color: "warning",
        message: "Por favor, ingrese un correo válido.",
        icon: "report_problem",
        position: "top",
        timeout: 2500,
      });
      return;
    }
    if (this.dataTraslado.email != this.correoConfirmacion) {
      Notify.create({
        color: "warning",
        message: "Los correos no coinciden",
        icon: "report_problem",
        position: "top",
        timeout: 2500,
      });
      return;
    }
    if (this.dataTraslado.id_comuna == "") {
      Notify.create({
        color: "warning",
        message: "Por favor, seleccione la comuna de su dirección.",
        icon: "report_problem",
        position: "top",
        timeout: 2500,
      });
      return;
    }
    if (this.dataTraslado.calle == "") {
      Notify.create({
        color: "warning",
        message: "Por favor, indique el nombre de la calle en su dirección.",
        icon: "report_problem",
        position: "top",
        timeout: 2500,
      });
      return;
    }
    if (this.dataTraslado.numero == "") {
      Notify.create({
        color: "warning",
        message: "Por favor, ingrese el número en su dirección",
        icon: "report_problem",
        position: "top",
        timeout: 2500,
      });
      return;
    }

    if (this.permiso == null) {
      Notify.create({
        color: "warning",
        message:
          "Por favor, ingrese el Permiso de Circulación del año anterior",
        icon: "report_problem",
        position: "top",
        timeout: 2500,
      });
      return;
    }
    if (this.revision == null) {
      Notify.create({
        color: "warning",
        message: "Por favor, ingrese Revisión técnica, Gases u Homologación",
        icon: "report_problem",
        position: "top",
        timeout: 2500,
      });
      return;
    }
    // if (this.factura == null) {
    //   Notify.create({
    //     color: "warning",
    //     message: "Por favor, ingrese la factura (soló para vehiculos 2023).",
    //     icon: "report_problem",
    //     position: "top",
    //     timeout: 2500,
    //   });
    //   return;
    // }
    if (this.certificado == null) {
      Notify.create({
        color: "warning",
        message: "Por favor, ingrese el Certificado de inscripción",
        icon: "report_problem",
        position: "top",
        timeout: 2500,
      });
      return;
    }
    
    if (this.soap == null) {
      Notify.create({
        color: "warning",
        message: "Por favor, ingrese el SOAP",
        icon: "report_problem",
        position: "top",
        timeout: 2500,
      });
      return;
    }


    const data = new FormData();
    // permiso
    if (this.permiso) {
      const peFile = this.permiso as Blob;
      data.append("archivo_permiso_anterior", peFile);
    } else {
      const peFile = new File(["file"], "archivo_permiso_anterior") as Blob;
      data.append("archivo_permiso_anterior", peFile);
    }
    // revisión
    if (this.revision) {
      const reFile = this.revision as Blob;
      data.append("archivo_revision_tecnica", reFile);
    } else {
      const reFile = new File(["file"], "archivo_revision_tecnica") as Blob;
      data.append("archivo_revision_tecnica", reFile);
    }
    // factura
    if (this.factura) {
      const faFile = this.factura as Blob;
      data.append("archivo_factura", faFile);
    } else {
      const faFile = new File(["file"], "archivo_factura") as Blob;
      data.append("archivo_factura", faFile);
    }
    // certificado
    if (this.certificado) {
      const ceFile = this.certificado as Blob;
      data.append("archivo_certificado_inscripcion", ceFile);
    } else {
      const ceFile = new File(
        ["file"],
        "archivo_certificado_inscripcion"
      ) as Blob;
      data.append("archivo_certificado_inscripcion", ceFile);
    }
    // soap
    if (this.soap) {
      const soFile = this.soap as Blob;
      data.append("archivo_soap", soFile);
    } else {
      const soFile = new File(["file"], "archivo_soap") as Blob;
      data.append("archivo_soap", soFile);
    }

    for (const prop in this.dataTraslado) {
      if (this.dataTraslado.hasOwnProperty(prop)) {
        // @ts-ignore
        if (this.dataTraslado[prop] || this.dataTraslado[prop] === 0) {
          // @ts-ignore
          data.append(prop, this.dataTraslado[prop]);
        }
      }
    }

    // for (const entry of data.entries()) {
    //   const [key, value] = entry;
    //   console.log(`${key}: ${value}`);
    // }
    this.$q.loading.show({
      delay: 100, // ms
    });
    this.$axios
      .post("formulario/traslados", data)
      .then((res: any) => {
        this.$q.loading.hide();
        this.$swal({
          icon: "success",
          title: "Formulado enviado",
          text: "Formulario enviado correctamente",
          confirmButtonText: "Aceptar",
        }).then((result) => {
          this.$router.push({ name: "Home" });
        });
      })
      .catch((err: any) => {
        if (err.response.data.message == "REGISTRADO") {
          this.$q.loading.hide();
          this.$swal({
            icon: "warning",
            title: "Patente registrada",
            text: "La patente ya se encuentra registrada",
            confirmButtonText: "Aceptar",
          });
          return;
        } else {
          this.$q.loading.hide();
          this.$swal({
            icon: "warning",
            title: "Error al enviar",
            text: "Ha ocurrido un error, por favor intente nuevamente",
            confirmButtonText: "Aceptar",
          });
          return;
        }
      });
  }

  private validEmail(email: string) {
    // eslint-disable-next-line
    let emailRegex: RegExp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (emailRegex.test(email) === true) {
      return true;
    } else {
      return false;
    }
  }

  // LIMITAR FECHAS POSTERIORES A LA ACTUAL
  // private optionsFn(date: any) {
  //   const currentDate = new Date().toISOString().split('T')[0]; // Obtener la fecha actual en formato "YYYY-MM-DD"
  //   const currentDateFormatted = currentDate.replace(/-/g, '/'); // Transformar la fecha actual a "YYYY/MM/DD"
  //   return date <= currentDateFormatted; // Permitir solo fechas anteriores o iguales a la fecha actual en formato "YYYY/MM/DD"
  // }

  public validateRut(newValue: string) {
    let texto = newValue;
    let tmpstr = "";
    for (let i = 0; i < texto.length; i++)
      if (
        texto.charAt(i) != " " &&
        texto.charAt(i) != "." &&
        texto.charAt(i) != "-"
      )
        tmpstr = tmpstr + texto.charAt(i);
    texto = tmpstr;
    const largo = texto.length;
    if (largo < 2) {
      return false;
    }
    for (let i = 0; i < largo; i++) {
      if (
        texto.charAt(i) != "0" &&
        texto.charAt(i) != "1" &&
        texto.charAt(i) != "2" &&
        texto.charAt(i) != "3" &&
        texto.charAt(i) != "4" &&
        texto.charAt(i) != "5" &&
        texto.charAt(i) != "6" &&
        texto.charAt(i) != "7" &&
        texto.charAt(i) != "8" &&
        texto.charAt(i) != "9" &&
        texto.charAt(i) != "k" &&
        texto.charAt(i) != "K"
      ) {
        return false;
      }
    }
    let invertido = "";
    for (let i = largo - 1, j = 0; i >= 0; i--, j++)
      invertido = invertido + texto.charAt(i);
    let dtexto = "";
    dtexto = dtexto + invertido.charAt(0);
    dtexto = dtexto + "-";
    let cnt = 0;
    for (let i = 1, j = 2; i < largo; i++, j++) {
      //alert("i=[" + i + "] j=[" + j +"]" );
      if (cnt == 3) {
        dtexto = dtexto + ".";
        j++;
        dtexto = dtexto + invertido.charAt(i);
        cnt = 1;
      } else {
        dtexto = dtexto + invertido.charAt(i);
        cnt++;
      }
    }
    invertido = "";
    for (let i = dtexto.length - 1, j = 0; i >= 0; i--, j++)
      invertido = invertido + dtexto.charAt(i);

    if (this.revisarDigito2(texto)) {
      return true;
    }
    return false;
  }
  public revisarDigito(dvr: string) {
    const dv = dvr + "";
    if (
      dv != "0" &&
      dv != "1" &&
      dv != "2" &&
      dv != "3" &&
      dv != "4" &&
      dv != "5" &&
      dv != "6" &&
      dv != "7" &&
      dv != "8" &&
      dv != "9" &&
      dv != "k" &&
      dv != "K"
    ) {
      return false;
    }
    return true;
  }
  public revisarDigito2(crut: string) {
    const largo = crut.length;
    if (largo < 2) {
      return false;
    }
    let rut = "";
    if (largo > 2) rut = crut.substring(0, largo - 1);
    else rut = crut.charAt(0);
    const dv = crut.charAt(largo - 1);
    this.revisarDigito(dv);
    if (rut == null || dv == null) return 0;
    let dvr = "0";
    let suma = 0;
    let mul = 2;
    for (let i = rut.length - 1; i >= 0; i--) {
      suma = suma + parseInt(rut.charAt(i), 10) * mul;
      if (mul == 7) mul = 2;
      else mul++;
    }
    const res = suma % 11;
    if (res == 1) dvr = "k";
    else if (res == 0) dvr = "0";
    else {
      const dvi = 11 - res;
      dvr = dvi + "";
    }
    if (dvr != dv.toLowerCase()) {
      return false;
    }
    return true;
  }

  private filterFnComuna(val: any, update: any, abort: any) {
    update(() => {
      const needle = val.toLowerCase();
      this.comunaOptions = this.cityList.filter(
        (v) => v.label.toLowerCase().indexOf(needle) > -1
      );
    });
  }

  private eliminar(x) {
    if (x == "permiso") {
      this.permiso = null;
    } else if (x == "revision") {
      this.revision = null;
    } else if (x == "certificado") {
      this.certificado = null;
    } else if (x == "factura") {
      this.factura = null;
    } else if (x == "soap") {
      this.soap = null;
    }
  }

  // private checkFile(target: any) {
  //   console.log(target);
  // let flag = false;
  // const extensions = ["jpg", "jpeg", "png", "pdf", "docx", "doc"];
  // if (target.files && target.files[0]) {
  //   for (const x in extensions) {
  //     if (target.files[0].type.includes(extensions[x])) {
  //       flag = true;
  //     }
  //   }
  //   if (flag == false) {
  //     this.$swal({
  //       icon: "warning",
  //       title: "Documento inválido",
  //       text: "El formato del archivo es incorrecto",
  //       confirmButtonText: "Aceptar",
  //     });
  //   } else if (target.files[0].size > 5000000) {
  //     this.invalidFile = true;
  //     this.$swal({
  //       icon: "warning",
  //       title: "Documento inválido",
  //       text: "El tamaño del documento excede los 5MB",
  //       confirmButtonText: "Aceptar",
  //     });
  //   } else if (target.files[0].size <= 0 || !flag) {
  //     this.invalidFile = true;
  //   } else {
  //     if (target.name === "permiso") {
  //       this.permiso = target.files[0];
  //     } else if (target.name === "revision") {
  //       this.revision = target.files[0];
  //     } else if (target.name === "factura") {
  //       this.factura = target.files[0];
  //     } else if (target.name === "certificado") {
  //       this.certificado = target.files[0];
  //     } else if (target.name === "soap") {
  //       this.soap = target.files[0];
  //     }
  //     this.invalidFile = false;
  //   }
  // }
  // }
}
